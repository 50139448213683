import React from 'react'
import ReactDOM from 'react-dom'

import { App as CapacitorApp } from '@capacitor/app'
import { SplashScreen } from '@capacitor/splash-screen'
import { CapacitorUpdater } from '@capgo/capacitor-updater'

import App from './App'
import * as serviceWorker from './serviceWorker'

// const whyDidYouRender = require('@welldone-software/why-did-you-render')

// whyDidYouRender(React, {
//   trackAllPureComponents: true,
// })

CapacitorUpdater.notifyAppReady()
CapacitorApp.addListener('appStateChange', async state => {
  let version
  if (state.isActive) {
    // Ensure download occurs while the app is active, or download may fail
    version = await CapacitorUpdater.download({
      version: '1.1.15',
      url: 'https://intelup-collector.s3.amazonaws.com/version-new.zip',
    })
    // }

    // if (!state.isActive && data.version !== '') {
    // Activate the update when the application is sent to background
    SplashScreen.show()
    try {
      await CapacitorUpdater.set(version)
      // At this point, the new version should be active, and will need to hide the splash screen
    } catch (Exception) {
      console.log(Exception)
      SplashScreen.hide() // Hide the splash screen again if something went wrong
    }
  }
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
